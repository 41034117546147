.saved-sketch {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  width: 15.5em;
  height: 14em;
  position: relative;
  background: white;
  border: 1px solid white;

  img {
    padding-top: 1em;
    max-width: 10em;
    max-height: 10em;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  > a {
    text-align: center;
    display: block;
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;

    > span {
      display: block;
      position: absolute;
      left: 0; right: 0; bottom: 0.5em;
      margin-left: 2.6em;
      margin-right: 2.6em;

      &.small {
        font-size: $font-size-sm;
      }
    }
  }

  &:HOVER {
    border: 1px solid black;
  }

  > div > button {
    position: absolute;
    bottom: 1px;
    &:first-child {
      right: 1px;
    }
    &:last-child {
      left: 1px;
    }

    &.sketch-share {
      left: 42px;
    }
  }
}

