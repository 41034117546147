.btn-link-animated {
  position: relative;
  text-decoration: none !important;

  &:before {
    content: "";
    position: absolute;
    bottom: 0.375rem;
    width: 0px;
    height: 2px;
    margin: 2px 0 0;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background-color: $primary;
  }

  &:hover {
    &:before {
      width: calc( 100% - 1.5rem );
      opacity: 1;
    }
  }
}


.button-icon {
  display: inline-block;
  width: 1em;
  height: 1rem;
}

div.modal div.modal-footer button.btn, .btn.btn-size {
  min-width: 6em;
}
