// Plik z konfiguracją dla Bootstrap.
// Musi być importowany, jako pierwszy, dalej już zgodnie z dokumentacją:
// https://getbootstrap.com/docs/4.5/getting-started/theming/#importing

$body-bg: #e8e8e8;
//$body-bg: #ffffff;
$primary: #14778a;
$success: #18788b;
$secondary: #ffeebe;
$yiq-text-dark: #464d57;
$headings-font-family: 'Exo',sans-serif;
$font-family-sans-serif: 'Hind', sans-serif;
$enable-rounded: false;
$light: #bcc3cd;
$info: #ffffff;
$dropdown-bg: #bcc3cd;
$dropdown-min-width: 15rem;
$dropdown-item-padding-y: 1.25rem;
$dropdown-padding-y: 0;
$dropdown-divider-bg: #ffffff;

$headings-color: #464d57;
