
.template-category {
  width: 15em;
  height: 2em;
  position: relative;
  background: white;
  border: 1px solid white;
  padding: 0.2em 1em;
  &:HOVER {
    border: 1px solid black;
  }
}

