.price-card {
  max-width: 24em;

  .card-title {
    color: $primary;
    padding: 1em 0;
  }

  .card-text {

  }

  .card-text.price-item {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

  h5.price {
    min-height: 2rem;
  }

  form {
    padding-left: 0.5rem;
    display: inline-block;
  }

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0.5rem;
  }

  &.selected {
    border: 1px solid black;
  }

  s {
    color: #777;
  }
}


@media (max-width: $bp-md) {
  .price-card {
    margin-right: auto;
    margin-left: auto;
  }
}

.p-section {
  padding-top: 4em;
  padding-bottom: 4em;
}

.p-def {
  padding-bottom: 4em;
}
