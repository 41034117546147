div.help-video {
  max-width: 1920px;
  text-align: center;
}

.guide-icon {
  height: 1.5em;
  margin-right: 0.5em;
}

.text-content {
  h2 {
    padding: 1.5rem 0;
  }
  h3 {
    padding: 1rem 0;
  }

  img {
    max-width: 100%;
  }
}
