// Dla komponentu tekstu, który się rozwija - FAQ na stronie głównej
.collapsible-text {
  > a {
    text-decoration: none;
    color: $yiq-text-dark;
    font-weight: bold;
    display: block;
  }
  > a:AFTER {
    content: '\26CC';
    float: right;
    transition: 0.3s;
  }

  &.open > a:AFTER {
    transform: rotate(45deg);
  }

  > div {
    transition: .3s;
    margin-bottom: 1em;
  }

  &.closed > div {
    transform: scale(0);
    height: 0;
  }

  &.open > div {
    transform: scale(1);
  }
}


.section-image-left {
  position: absolute;
  left: 0;
  width: 221px;
  top: 0; bottom: 0;

  background: no-repeat center right;
}

.section-image-right {
  position: absolute;
  right: 0;
  width: 221px;
  top: 0; bottom: 0;
  background: no-repeat center left;
}

@media (max-width: $bp-lg) {
  .section-image-left, .section-image-right {
    display: none;
  }
}


.step-icon {
  font-size: 2rem;
  font-weight: 800;
  border: .125rem solid #1f788a;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-step {
  max-height: 19em;
  margin: 3em 0;
  max-width: 100%;
  width: auto;
}


@media (max-width: 980px) and (min-width: 768px) {
  .monitor-image {
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .monitor-image {

  }
}

@media (max-width: 500px) {
  .monitor-image {
    visibility: hidden;
  }
}

.play-button {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 1.9rem;

  span {
    background-color: #000d;
    color: #fff;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 6px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;

    i {
      margin-top: 0.3em;
      margin-bottom: 0.3em;
      display: block;
      width: 0;
      height: 0;
      border-top: 0.4em solid transparent;
      border-bottom: 0.4em solid transparent;
      border-left: 0.4em solid #fff;
    }

    &:hover {
      background-color: rgb(0, 173, 239);
    }
  }
}

#scroll-top {
  position: fixed;
  cursor: pointer;
  right: 1rem;
  bottom: 1rem;
  transform: rotate(270deg);
  z-index: 1500;
  font-size: 3rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-content: center;
  align-items: center;
  color: $primary;
  line-height: 3rem;

  > span {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    color: #464d57;
  }
}

// Nie działa na Safari
//.p-section {
//  scroll-margin-top: 3rem;
//}

.landing {
  h1 {
    @media screen and (max-width: 1199px) {
      font-size: 2rem;
    }
    @media screen and (min-width: 1200px) {
      font-size: 2.5rem;
    }
  }
}

div.row.images {
  > div {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}

div.help-content {
  h1 {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h2 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h3 {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

div.image-title {
  display: inline-block;
  position: relative;

  span {
    color: #ffebc1;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    margin: 0;
    padding: 0;
    transform: translateY(-50%);
    font-size: $h1-font-size;
    user-select: none;
  }

  @include small-screen-down {
    span {
      font-size: $h5-font-size;
    }
  }
}