.legal-list {
  counter-reset: list;
  > li {
    list-style: none;
    &:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
    }
  }
}

