@media (min-width: $bp-lg) {
  .nav-spacing > .nav-item:not(:last-child) {
    margin-right: 1.5em;
  }
}

@media (max-width: $bp-lg) {
  .nav-spacing > .nav-item:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.navbar-border-bottom2 {
  border-bottom: 1px solid #ccc;
}

#kb-sk {
  div.tooltip-inner {
    max-width: 80%;
    width: 30em;
    text-align: left;

    ul {
      list-style: none;
      padding: 0;
      margin-left: 0.5em;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

}