// tyle związane z drukiem

@page {
  size: A4;
  margin: 20mm 20mm 20mm 20mm;

  @bottom-left {
    content: "© 2020 Archiplaner.pl Wszelkie prawa zastrzeżone."
  }
  @bottom-right {
    content: counter(page) " / " counter(pages);
  }
}

@media screen {
  .screen-hidden {
    display: none !important;
  }
}

@media print {
  footer, div#scroll-top, .cookie-info {
    display: none !important;
  }

  .print-hidden {
    display: none !important;
  }

}