.cookie-info {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000d;
  font-size: 0.8rem;
  padding: 0.2rem 1rem 0.5rem 1rem;
  z-index: 2157483640;
  text-align: center;

  color: #ddd;

  p {
    padding: 0;
    margin: 0.5rem 0;
  }

  button {
    background-color: #ddd;
    outline: none;
    border: none;
    color: black;
    font-size: 1rem;
  }

}
