@use "sass:map";

$bp-lg: map-get($grid-breakpoints, "lg");
$bp-md: map-get($grid-breakpoints, "md");

@each $bp, $width in $container-max-widths {
  .mw-#{$bp} {
    max-width: $width;
  }
}

.anchor {
  scroll-margin-top: 2.4em;
}

.uppercase {
  text-transform: uppercase;
}

@mixin small-screen-down {
  @media (max-width: 639.9px) {
    @content;
  }
}

@mixin small-screen-up {
  @media (min-width: 630px) {
    @content;
  }
}