@mixin column-width($name, $width, $align: left) {
  td.#{$name}, th.#{$name} {
    max-width: $width;
    min-width: $width;
    width: $width;
  }
  @if $align!=left {
    td.#{name} {
      text-align: $align;
    }
  }
}

@mixin column-min-width($name, $width) {
  td.#{$name}, th.#{$name} {
    min-width: $width;
  }
}


@if variable-exists(include-columns) {
  table {
    @include column-width("id", 4em);
    @include column-width("exports", 9em);
    @include column-width("date", 7em);
    @include column-width("date-time", 9em);
    @include column-width("name", 14em);
    @include column-width("money", 8em, right);

    td.id, td.exports, td.money {
      text-align: right;
    }
  }
}
